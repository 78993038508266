import React from "react"
import styled from "@emotion/styled"
import { ClassNames } from "@emotion/core"
import { Image, Block, Button, Loader } from "components/atoms"
import IconButton from "@material-ui/core/IconButton"
import moment from "moment"
import Swal from "sweetalert2"

// ICONS
import MailOutlined from "@material-ui/icons/MailOutlined"
import CakeOutlined from "@material-ui/icons/CakeOutlined"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import CloudUpload from "@material-ui/icons/CloudUpload"
import Edit from "@material-ui/icons/Edit"

import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"

import { uploadFile } from "firebases/storage"
import CloudApi from "api/cloudApi"
import Genders from "assets/genders.svg"

// lodash
import get from "lodash/get"

const EditProfile = styled(
  ({
    className,
    photoURL,
    displayName,
    email,
    birthday,
    goBack,
    uid,
    getProfile,
    modal,
    themeData,
    gender = null,
  }) => {
    const [values, setValues] = React.useState({
      displayName,
      birthday: moment(birthday, "MM/DD/YYYY").format("YYYY-MM-DD"),
      photoURL,
      photo: null,
      loading: false,
      email,
      gender,
    })

    const handleChange = prop => event => {
      setValues({ ...values, [prop]: event.target.value })
    }

    const handleUploadPhoto = ({ target }) => {
      if (target.files && target.files.length) {
        const acceptFileType = [".gif", ".jpg", ".jpeg", ".png"]
        const extension = target.files[0].name
          .match(/\.[0-9a-z]+$/i)
          .pop()
          .toLowerCase()
        const isSuccess = acceptFileType.indexOf(extension) > -1
        if (isSuccess) {
          const reader = new FileReader()
          reader.onload = e => {
            setValues({
              ...values,
              photoURL: e.target.result,
              photo: target.files[0],
            })
          }

          reader.readAsDataURL(target.files[0])
        } else {
          target.value = ""
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Invalid file type!",
          })
        }
      }
    }

    const handleSubmit = e => {
      e.preventDefault()
      setValues({ ...values, loading: true })
      let promise = Promise.resolve(photoURL)
      if (values.photo) {
        promise = uploadFile(values.photo, `/users/${uid}`, "photoURL")
      }
      const formattedBirthday = moment(values.birthday, "YYYY-MM-DD").format(
        "MM/DD/YYYY"
      )
      promise.then(result => {
        const fontFamily = get(themeData, "fonts.fontFamily")
        const fontSize = get(themeData, "fonts.fontSize")
        return CloudApi.updateProfile({
          photoURL: result,
          birthday:
            formattedBirthday === "Invalid date" ? null : formattedBirthday,
          displayName: values.displayName,
          email: values.email,
          gender: values.gender,
        }).then(() => {
          getProfile().then(() => {
            goBack()
            modal.fire({
              html: `<p style="font-family: ${fontFamily}; font-size: ${fontSize}; margin: 0;">Your profile has been edited successfully.</p>`,
            })
          })
        })
      })
    }
    if (values.loading) return <Loader />
    return (
      <ClassNames>
        {({ cx }) => (
          <Block className={cx("editprofile-box", className)} width={"100%"}>
            <IconButton onClick={goBack}>
              <KeyboardArrowLeft
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>
            <Block.Flex
              className={"profile-box"}
              mx={"auto"}
              flexDirection={"column"}
            >
              <Block
                background={"white"}
                border={"1px solid #d0d0d0"}
                borderRadius={"50%"}
                position={"relative"}
                mb={20}
              >
                <Image
                  src={values.photoURL}
                  objectFit={"cover"}
                  borderRadius={"50%"}
                  border={"7px solid white"}
                  height={[90, 160]}
                  width={[90, 160]}
                  circular
                />
                <label>
                  <Block
                    className={"upload-label"}
                    width={[105, 175]}
                    height={[45, 80]}
                    left={0}
                    top={[60, 86]}
                    css={{
                      ".MuiSvgIcon-root": {
                        fontSize: 30,
                        color: "rgba(255, 255, 255, .7)",
                        position: "absolute",
                        top: 20,
                        left: 70,
                        "@media (max-width: 640px)": {
                          fontSize: 22,
                          top: 14,
                          left: 43,
                        },
                      },
                    }}
                    style={{
                      display: "inline-block",
                      borderBottomLeftRadius: 170,
                      borderBottomRightRadius: 170,
                      position: "absolute",
                      background: "rgba(0, 0, 0, .3)",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="file"
                      onChange={handleUploadPhoto}
                      accept=".gif,.jpg,.jpeg,.png"
                      style={{ display: "none" }}
                      multiple
                    />
                    <CloudUpload />
                  </Block>
                </label>
              </Block>
              <form onSubmit={handleSubmit}>
                <Block
                  css={{
                    ".MuiButton-containedPrimary": {
                      color: "white",
                    },
                  }}
                >
                  <Block.Flex
                    className={"detail-flex"}
                    css={{
                      ".MuiSvgIcon-root": {
                        fontSize: 30,
                        opacity: 0.3,
                        "@media (max-width: 640px)": {
                          fontSize: 24,
                        },
                      },
                    }}
                  >
                    <Edit />
                    <Block ml={[15, 25]}>
                      <TextField
                        label={"Name"}
                        id="textfield-name"
                        value={values.displayName}
                        required
                        fullWidth
                        onChange={handleChange("displayName")}
                      />
                    </Block>
                  </Block.Flex>
                  <Block.Flex
                    className={"detail-flex"}
                    css={{
                      ".MuiSvgIcon-root": {
                        fontSize: 30,
                        opacity: 0.3,
                        "@media (max-width: 640px)": {
                          fontSize: 24,
                        },
                      },
                    }}
                  >
                    <MailOutlined />
                    <Block ml={[15, 25]}>
                      <TextField
                        label="Email"
                        id="textfield-Email"
                        value={values.email !== "" ? values.email : " "}
                        fullWidth
                        disabled={email !== ""}
                        onChange={handleChange("email")}
                      />
                    </Block>
                  </Block.Flex>
                  <Block.Flex
                    className={"detail-flex"}
                    css={{
                      ".MuiSvgIcon-root": {
                        fontSize: 30,
                        opacity: 0.3,
                        "@media (max-width: 640px)": {
                          fontSize: 24,
                        },
                      },
                    }}
                  >
                    <CakeOutlined />
                    <Block ml={[15, 25]}>
                      <TextField
                        id="textfield-birthday"
                        label="Birthday"
                        type="date"
                        fullWidth
                        disabled={Boolean(birthday)}
                        value={values.birthday}
                        onChange={handleChange("birthday")}
                        InputProps={{
                          inputProps: { max: moment().format("YYYY-MM-DD") },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Block>
                  </Block.Flex>
                  <Block.Flex className={"detail-flex"}>
                    <Image src={Genders} width={30} />
                    <Block ml={[15, 25]} width="100%">
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Gender</InputLabel>
                        <Select
                          style={{ width: "100%" }}
                          value={values.gender}
                          onChange={handleChange("gender")}
                          disabled={!!values.gender}
                        >
                          <MenuItem value={"male"}>ชาย</MenuItem>
                          <MenuItem value={"female"}>หญิง</MenuItem>
                          <MenuItem value={"other"}>อื่นๆ</MenuItem>
                          <MenuItem value={"rather not say"}>ไม่ระบุ</MenuItem>
                        </Select>
                      </FormControl>
                    </Block>
                  </Block.Flex>
                  <Button.Contain type="submit" fullWidth>
                    Submit
                  </Button.Contain>
                </Block>
              </form>
            </Block.Flex>
          </Block>
        )}
      </ClassNames>
    )
  }
)(themeData => ({
  ".profile-box": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    wordBreak: "break-word",
  },
  ".detail-flex": {
    width: "100%",
    alignItems: "flex-end",
    margin: "20px 0px",
  },
  ".upload-label:hover": {
    opacity: 0.7,
  },
  ".MuiInputLabel-formControl": {
    transform: "translate(0, 45px) scale(1)",
  },
  ".MuiInputLabel-shrink": {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
  ".MuiInput-root": {
    width: 370,
    "@media only screen and (max-width: 450px)": {
      width: 250,
    },
  },
}))

export default EditProfile
