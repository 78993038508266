import React from "react"
import styled from "@emotion/styled"
import { ClassNames } from "@emotion/core"
import { Image, Text, Block } from "components/atoms"
import moment from "moment"
import get from "lodash/get"
import { auth } from "firebases/config"

// ICONS
import Edit from "@material-ui/icons/Edit"
import MailOutlined from "@material-ui/icons/MailOutlined"
import CakeOutlined from "@material-ui/icons/CakeOutlined"
import LockOutlined from "@material-ui/icons/LockOutlined"

import Genders from "assets/genders.svg"

const Profile = styled(
  ({
     className,
     birthday,
     email,
     displayName,
     photoURL,
     editProfile,
     changePassword,
     themeData,
     gender,
   }) => {
    const hasPassword = () => {
      const providerDatas = get(auth.currentUser, "providerData", [])
      const providerPassword = providerDatas.filter(
        providerData => providerData.providerId === "password",
      )
      if (providerPassword.length !== 0) {
        return true
      }
      return false
    }
    const lightPrimary = get(themeData, "palette.primary.main")
    // const darkPrimary = get(themeData, "palette.primary.dark")

    const renderGender = gender => {
      if (gender) {
        if (gender === "male") return "ชาย"
        else if (gender === "female") return "หญิง"
        else if (gender === "other") return "อื่นๆ"
        else return "ไม่ระบุ"
      }
      return null
    }
    return (
      <ClassNames>
        {({ cx }) => (
          <Block
            className={cx("privilege-box", className)}
            width={["initial", 370]}
            mx={"auto"}
          >
            <Block
              background={"white"}
              border={"1px solid #d0d0d0"}
              borderRadius={"50%"}
            >
              <Image
                src={photoURL}
                objectFit={"cover"}
                borderRadius={"50%"}
                border={"7px solid white"}
                height={[90, 160]}
                width={[90, 160]}
                circular
              />
            </Block>
            <Text mt={10} mb={"10px"} lineHeight={1.2} textAlign={"center"}>
              <h3>{displayName}</h3>
            </Text>
            <Block.Flex
              alignItems={"center"}
              onClick={editProfile}
              style={{ cursor: "pointer" }}
              css={{
                ".MuiSvgIcon-root": {
                  fontSize: 26,
                  "@media (max-width: 640px)": {
                    fontSize: 15,
                  },
                },
              }}
            >
              <Edit style={{ color: lightPrimary }} />
              <Text color={lightPrimary} ml={"7px"}>
                <h3> Edit profile </h3>
              </Text>
            </Block.Flex>
            <Block py={[15, 30]} width={"100%"}>
              <Block className={"detail"} p={["10px", 20]}>
                <Block.Flex alignItems={"center"}>
                  <MailOutlined />
                  <Text ml={20}>{email !== "" ? email : "Not set"}</Text>
                </Block.Flex>
              </Block>
              <Block className={"detail"} p={["10px", 20]}>
                <Block.Flex alignItems={"center"}>
                  <CakeOutlined />
                  <Text ml={20}>
                    {typeof birthday !== "undefined"
                      ? moment(birthday, "MM/DD/YYYY").format("MMM DD, YYYY")
                      : "Not set"}
                  </Text>
                </Block.Flex>
              </Block>
              <Block className={"detail"} p={["10px", 20]}>
                <Block.Flex alignItems={"center"}>
                  <Image src={Genders} width={30} />
                  <Text ml={20}>{renderGender(gender) || "-"}</Text>
                </Block.Flex>
              </Block>
              {hasPassword() && (
                <Block className={"detail"} p={["10px", 20]}>
                  <Block.Flex
                    alignItems={"center"}
                    onClick={changePassword}
                    style={{ cursor: "pointer" }}
                  >
                    <LockOutlined />
                    <Text ml={20}>Change password</Text>
                  </Block.Flex>
                </Block>
              )}
            </Block>
          </Block>
        )}
      </ClassNames>
    )
  },
)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  wordBreak: "break-word",
  ".link": {
    textDecoration: "none",
  },
  ".detail": {
    width: "100%",
    borderBottom: "2px solid #F0F0F0",
  },
  ".detail svg": {
    fontSize: 30,
    opacity: 0.3,
    color: "black",
  },
}))

export default Profile
