import React from "react"
import styled from "@emotion/styled"
import { ClassNames } from "@emotion/core"
import { Image, Text, Block, Button, Loader } from "components/atoms"

import IconButton from "@material-ui/core/IconButton"

import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormControl from "@material-ui/core/FormControl"

// ICONS
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"

import { auth } from "firebases/config"
import { emailAuthProvider } from "firebases/config"

import image from "../../assets/password-reset.png"

// lodash
import get from "lodash/get"

const ChangePassword = styled(
  ({ className, goBack, getProfile, modal, themeData }) => {
    const [values, setValues] = React.useState({
      password: "",
      newPassword: "",
      confirmPassword: "",
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      errorCode: null,
      loading: false,
    })

    const handleChange = prop => event => {
      setValues({ ...values, [prop]: event.target.value })
    }

    const handleClickShowPassword = prop => {
      setValues({ ...values, [prop]: !values[prop] })
    }

    const handleSubmit = e => {
      e.preventDefault()
      const { password, newPassword, confirmPassword } = values
      const fontFamily = get(themeData, "fonts.fontFamily")
      const fontSize = get(themeData, "fonts.fontSize")
      if (newPassword === confirmPassword) {
        setValues({ ...values, loading: true })
        const cred = emailAuthProvider.credential(
          auth.currentUser.email,
          password
        )

        auth.currentUser
          .reauthenticateWithCredential(cred)
          .then(() => {
            return auth.currentUser.updatePassword(newPassword).then(() => {
              getProfile().then(() => {
                goBack()
                modal.fire({
                  html: `<p style="font-family: ${fontFamily}; font-size: ${fontSize}; margin: 0;">Your password has been changed successfully.</p>`,
                })
              })
            })
          })
          .catch(err => {
            setValues({
              ...values,
              errorCode: "Password is incorrect",
            })
          })
      } else {
        setValues({
          ...values,
          errorCode: "New password does not match",
        })
      }
    }

    if (values.loading) return <Loader />
    return (
      <ClassNames>
        {({ cx }) => (
          <Block className={cx("changePassword-box", className)} width={"100%"}>
            <IconButton onClick={goBack}>
              <KeyboardArrowLeft
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>
            <Block.Flex
              className={"form-box"}
              flexDirection={"column"}
              mx={"auto"}
              width={["auto", 370]}
            >
              <Image src={image} objectFit={"contain"} />
              <Text textAlign={"center"} mt={25} color={"primary"}>
                <h3>Change Password</h3>
              </Text>
              <form onSubmit={handleSubmit}>
                <FormControl
                  required
                  error={values.errorCode === "Password is incorrect"}
                >
                  <InputLabel htmlFor="adornment-password">Password</InputLabel>
                  <Input
                    id="adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    inputProps={{ minLength: 6 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword("showPassword")
                          }
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {values.errorCode === "Password is incorrect" && (
                    <Text className={"input-error"}>Password is incorrect</Text>
                  )}
                </FormControl>
                <FormControl
                  required
                  error={values.errorCode === "New password does not match"}
                >
                  <InputLabel htmlFor="adornment-newPassword">
                    New Password
                  </InputLabel>
                  <Input
                    id="adornment-newPassword"
                    type={values.showNewPassword ? "text" : "password"}
                    value={values.newPassword}
                    onChange={handleChange("newPassword")}
                    inputProps={{ minLength: 6 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword("showNewPassword")
                          }
                        >
                          {values.showNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl
                  required
                  error={values.errorCode === "New password does not match"}
                >
                  <InputLabel htmlFor="adornment-confirmPassword">
                    Re-enter New Password
                  </InputLabel>
                  <Input
                    id="adornment-confirmPassword"
                    type={values.showConfirmPassword ? "text" : "password"}
                    value={values.confirmPassword}
                    onChange={handleChange("confirmPassword")}
                    inputProps={{ minLength: 6 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword("showConfirmPassword")
                          }
                        >
                          {values.showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {values.errorCode === "New password does not match" && (
                    <Text className={"input-error"}>
                      New password does not match
                    </Text>
                  )}
                </FormControl>
                <Button.Contain type="submit" color={"primary"} fullWidth>
                  Submit
                </Button.Contain>
              </form>
            </Block.Flex>
          </Block>
        )}
      </ClassNames>
    )
  }
)(() => ({
  ".form-box": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    wordBreak: "break-word",
  },
  ".MuiFormControl-root": {
    width: "100%",
    margin: "20px 0px",
  },
  ".input-error": {
    textAlign: "center",
    color: "red",
  },
}))

export default ChangePassword
