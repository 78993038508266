import React, { Component } from "react"
import styled from "@emotion/styled"
import { Block, Text } from "components/atoms"
import { MyCard, CouponsSection } from "components/molecules"
import withProfile from "hocs/withProfile"
import { CardReward } from "components/molecules"
import { PersonalInfo } from "components/organisms/PersonalInfo"
import get from "lodash/get"
import { Header } from "components/molecules"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

const StyledBlock = styled("div")({
  ".menu": {
    borderBottom: "2px solid #e6e6e6",
  },
})

class MyAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    }
  }

  selectCategory = value => {
    this.setState({ value: value })
  }

  renderTextButton = category => {
    const { theme } = this.props
    const primary = get(theme, "colors.primary")
    let value = 0
    if (category === "My Card") value = 0
    else if (category === "My Coupon") value = 1
    else value = 2

    return (
      <Block.Flex
        height={"100%"}
        css={{
          cursor: "pointer",
        }}
        onClick={() => this.selectCategory(value)}
      >
        <Text
          style={{
            padding: "10px",
            borderBottom: this.state.value === value && `4px solid ${primary}`,
            position: "relative",
            top: "2px",
          }}
          color={this.state.value === value ? primary: 'initial'}
          mx={['5px', '12px', '16px', '24px']}
          fontWeight={this.state.value === value ? "bold" : 500}
        >
          <h6>{category}</h6>
        </Text>
      </Block.Flex>
    )
  }

  renderList = () => {
    const brandId = get(this.props, "pageContext.brandId")
    const { value } = this.state
    const { profile, data } = this.props
    const brand = get(data, "backendData.brand")
    const userCards = get(profile, "cards", [])
    const brandInUerCard = userCards.find(card => card.brand.id === brandId)
    const point = get(brandInUerCard, "point", 0)
    const cover = get(brand, "cover")

    if (value === 0) {
      return (
        <Block>
          <MyCard point={point} image={cover} />
          <Block py={[20, 65]}>
            <Text textAlign={"center"} fontSize={"2rem"}>
              Your Rewards
            </Text>
            <CardReward brand={brand} userPoint={point} brandId={brandId} />
          </Block>
        </Block>
      )
    }
    if (value === 1) {
      return <CouponsSection brandId={brandId} />
    }
    return <PersonalInfo profile={profile} theme={this.props.theme} />
  }
  render() {
    return (
      <StyledBlock>
        <Header />
        <Block maxWidth={1200} mx={"auto"} pt={[15, 25]} pb={[80, 0]} width={['100%', '90vw']}>
          <Text pl={[20, 0]}>
            <h2>My Account</h2>
          </Text>
          <Block className="menu" display={"flex"} mt={[15, 20]}>
            {this.renderTextButton("My Card")}
            {this.renderTextButton("My Coupon")}
            {this.renderTextButton("Personal Info")}
          </Block>
          <Block mt={[0, 30]}>{this.renderList()}</Block>
        </Block>
      </StyledBlock>
    )
  }
}

export default withProfile(withEmotion(MyAccount))
