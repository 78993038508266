import React from "react"
import { Block } from "components/atoms"
import Profile from "components/molecules/Profile"
import EditProfile from "components/forms/EditProfile"
import ChangePassword from "components/forms/ChangePassword"
import Swal from "sweetalert2"
import get from "lodash/get"

// // REDUX
import { selectAuthData } from "modules/auth/selectors"
import { connect } from "react-redux"
import { getProfile } from "modules/auth/actions"
import { bindActionCreators } from "redux"

// import { withTheme } from "hocs"

class PersonalInfo extends React.Component {
  constructor(props) {
    super()
    this.state = {
      editProfile: false,
      changePassword: false,
    }
    this.modal = Swal.mixin({
      title: `<h2 style="font-family: ${get(
        props.theme,
        "fonts.fontFamily"
      )}; font-weight: 600; margin:0"}>Success!</h2>`,
      type: "success",
      confirmButtonColor: get(props.theme.colors, "primary"),
      confirmButtonText: `<p style="font-family: ${get(
        props.theme,
        "fonts.fontFamily"
      )}; font-size: ${get(props.theme, "fonts.fontSize")}; margin: 0;">OK</p>`,
    })
  }

  render() {
    const { editProfile, changePassword } = this.state
    const { profile, getProfile, theme } = this.props
    const { birthday, email, displayName, photoURL, uid, gender } = profile
    return (
      <Block p={[20, 0]}>
        {editProfile ? (
          <EditProfile
            birthday={birthday}
            email={email}
            displayName={displayName}
            photoURL={photoURL}
            goBack={() => this.setState({ editProfile: false })}
            uid={uid}
            getProfile={getProfile}
            modal={this.modal}
            themeData={theme}
            gender={gender}
          />
        ) : changePassword ? (
          <ChangePassword
            goBack={() => this.setState({ changePassword: false })}
            getProfile={getProfile}
            modal={this.modal}
            themeData={theme}
          />
        ) : (
          <Profile
            birthday={birthday}
            email={email}
            displayName={displayName}
            photoURL={photoURL}
            editProfile={() => this.setState({ editProfile: true })}
            changePassword={() => this.setState({ changePassword: true })}
            themeData={theme}
            gender={gender}
          />
        )}
      </Block>
    )
  }
}

const mapStateToProps = state => ({
  profile: selectAuthData(state),
})

export default connect(
  mapStateToProps,
  dispatch =>
    bindActionCreators(
      {
        getProfile,
      },
      dispatch
    )
)(PersonalInfo)
